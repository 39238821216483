<template>
  <div class="dashboard">
    <div class="row">
      <div class="col-12">
          <SectionTabs :tabs="tab_component"/>
      </div>
    </div>
    <div class="row my-3">
      <div class="col-12 section-head">
        <div class="row">
          <div class="col-12 col-lg-6 d-flex align-items-center">          
            <h3 class="section-head-title ps-0 mb-3 mb-lg-0"> 
              Conoce las cifras más importantes de <strong>Reconoce</strong>
            </h3>
          </div>
          <div class="col-12 col-lg-6">
            <div class="row">
              <div class="col-12 col-lg-3 col-xl-4 d-flex align-items-center justify-content-lg-end text-secondary">
                Filtrar por periodo:
              </div>
              <div class="col-12 col-lg-9 col-xl-8">
                <div class="row">
                  <div class="col-6">
                    <InputDate 
                      :max="range.date_end"
                      placeHolder="Inicio"
                      @dateEmit="captureDateStart"
                    />
                  </div>
                  <div class="col-6">
                    <InputDate 
                      :min="range.date_start" 
                      placeHolder="Término"
                      @dateEmit="captureDateEnd"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-lg-6">

            <!--CARD NUMBERS-->
            <div class="card card-numbers border-round-10 card-shadow mb-3">
              <div class="card-body">
                <div class="card-numbers-head d-flex align-items-center color-main">
                  <img class="card-numbers-head-icon" src="@/assets/img/reconoze/star.svg" alt="" />
                  <div class="card-numbers-head-num">{{ dash_data.total_reconocidos }}</div>
                  <div class="card-numbers-head-text">
                    Usuarios <br>
                    reconocidos
                  </div>
                </div>
                <div class="card-numbers-text mt-3">
                  {{ intro_text }}
                </div>
              </div>
            </div>

            <!--CARD PIE-->
            <Loading 
              v-if="loading"
              pixeles="150px"
            />
            <template v-if="!loading">         
              <div 
                class="card card-pie border-round-10 card-shadow mb-3 mb-lg-0" 
                v-show="showChart()">
                <div class="card-body">
                    <div class="row">
                      <div class="col-7">
                        <div class="card-pie-graph">
                          <apexchart
                            :key="key_dash" 
                            :options="chartOptions" 
                            :series="series">
                          </apexchart>
                        </div>
                      </div>
                      <div class="col-5">
                        <div class="card-pie-legend d-flex flex-column justify-content-center align-items-start ps-3">
                          <h5 class="mb-3">Estadísticas según relación</h5>
                          <div 
                            class="card-pie-legend-text d-flex align-items-center" 
                            v-for="(rela, i) in dash_data.total_relaciones" 
                            :key="i">
                            <div 
                              class="card-pie-legend-dot" 
                              :style="`background-color: ${chartOptions.colors[i]};`">
                            </div>
                            {{ rela.nombre_relacion }}
                          </div>
                        </div>                  
                      </div>
                    </div>
                </div>
              </div>
            </template>
          </div>
          <div class="col-12 col-lg-6">
            <div class="row">

              <!--CARD BARS-->
              <Loading 
                v-if="loading"
                pixeles="150px"
              />
              <template v-if="!loading">             
                <div 
                  class="col-12 col-xl-6 mb-3" 
                  v-for="(data, i) in dash_data.total_valores" :key="i">
                  <div class="card card-shadow card-bars border-round-10">
                    <div class="card-body">
                      <div class="card-bars-title">{{ data.nombre_valor }}</div>
                      <div class="card-bars-count my-2">
                        <div class="card-bars-count-num pe-2">{{ data.cantidad }}</div>
                        <div class="card-bars-count-val">
                          Reconocimientos de {{ dash_data.total_reconocimientos }}
                        </div>
                      </div>
                      <div class="card-bars-bar">
                        <div class="card-bars-bar-back bg-blue-gray">
                        </div>
                        <div 
                          class="card-bars-bar-front bg-blue"
                          :style="`width:${porcentajeReconocimientos(data.cantidad, dash_data.total_reconocimientos)}%;`">
                        </div>
                      </div>
                    </div>
                  </div>      
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionTabs from "../Section/SectionTabs.vue";
import { mapActions } from "vuex";
import moment from "moment";

//FF
import InputDate from "@/components/forms/InputDate.vue"
  import Loading from "@/components/Loading.vue";

export default {
  components: {
    SectionTabs,

    //FF
    InputDate,
    Loading
  },
  data() {
    return {
      show_spinner: false,
      tab_component: [
        {
          name: "Dashboard",
          route: "recognize-Dashboard",
        },
        {
          name: "Indicadores",
          route: "recognize-year",
        },
      ],
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      title_component: {
        subtitle:
          "¡Conoce el impacto de Reconoce! Podrás revisar cifras anuales, por gerencia, top 20, lugar de trabajo y colaborador.",
      },
      series: [],
      chartOptions: {
        chart: {
          type: "donut",
          height: 'auto',
          fontFamily: "Product Sans Regular",
          dropShadow: {
            enabled: true,
            top: 0,
            left: 0,
            blur: 1,
            color: "#0072f7",
            opacity: 0.35,
          },
        },
        dataLabels: {
          style: {
            colors: ["#fff"],
            fontSize: "16px",
            fontWeight: "bold",
          },
          dropShadow: {
            top: 0,
            left: 0,
            blur: 1,
            color: "#0072f7",
            opacity: 0.45,
          },
        },
        colors: ["#0072f7", "#7E88FD", "#B4B4FF"],
        legend: {
          show: false,
        },
        stroke: {
          show: false,
          curve: "smooth",
          lineCap: "butt",
          colors: ["#fff"],
          width: 2,
          dashArray: 0,
        },
        plotOptions: {
          pie: {
            startAngle: 0,
            endAngle: 360,
            customScale: 1,
            dataLabels: {
              offset: 0,
              minAngleToShowLabel: 10,
            },
            donut: {
              size: "50%",
              background: "transparent",
            },
          },
        },
        responsive: [
          {
            breakpoint: 768,
            options: {
              plotOptions: {
                bar: {
                  horizontal: false
                }
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ]
      },

      valores: [],
      value_colors: [],
      dash_data: {},
      show_calendar: false,
      calendar_side: "",
      fecha_inicio: "Inicio",
      fecha_fin: "Término",
      max_date: "",
      min_date: "",
      key_dash: 1,
      img_calendar: require("@/assets/img/utilities/calendar.svg"),

      //FF
      intro_text: "Reconoce es una potente herramienta para mantener viva la cultura. Te recomendamos incorporarla en tus comunicaciones e ir analizando las estadísticas para evaluar su impacto.",
      loading: true,
      range: {
        date_start: null,
        date_end: null,
        mask_start: null,
        mask_end: null
      }
    };
  },

  async created() {
    await this.getValores();
    await this.getEstadisticas();
  },

  watch: {
    "range.date_start"() {
        this.getEstadisticas();
    },
    "range.date_end"() {
        this.getEstadisticas();    
    },
  },

  methods: {
    ...mapActions("recognizeModule", ["getValoresAction", "getEstadisticasReconocimientosAction"]),

    showChart(){
      var suma = 0;
      for (let i = 0; i < this.series.length; i++) {
        const element = this.series[i];
        suma=suma+Number(element)
      }
      if(suma == 0){return false;}
      if(suma != 0){return true;}
    },  

    async getValores() {
      this.value_colors = {};
      
      try {        
        this.valores = await this.getValoresAction();
        this.valores.forEach((el) => {
          this.value_colors[el.nombre_valor] = el.color;
        });
      } catch (error) {
        this.toastGenericError();
        console.error(error);
      }
    },

    async getEstadisticas() {
      this.loading = true;
      const payload = {
        id_empresa: this.id_empresa,
        fecha_inicio: this.range.date_start !== null ? this.range.mask_start : '',
        fecha_final: this.range.date_end !== null ? this.range.mask_end : moment().endOf('month').format('YYYY-MM-DD'),
      };
      this.dash_data = await this.getEstadisticasReconocimientosAction(payload);
      this.loading = false;
      if (!this.dash_data) {
        this.toastGenericError();
        return;
      }
      var v_series = [];
      var v_chartOptions = [];
      for (let i = 0; i < this.dash_data.total_relaciones.length; i++) {
        var el = this.dash_data.total_relaciones[i];
        v_chartOptions.push(`${el.nombre_relacion}`);
        v_series.push(Number(el.cantidad.split(".")[0]));
      }
      this.series = v_series;
      this.chartOptions.labels = v_chartOptions;

      setTimeout(() => { this.key_dash = this.key_dash + 1; }, 100);
    },

    porcentajeReconocimientos(numRecons, numTotal) {
      return (+numRecons / +numTotal)*100;
    },

    //FF
    captureDateStart(date) {
      this.range.date_start = date;
      this.range.mask_start = moment(date).format('YYYY-MM-DD');
    },
    captureDateEnd(date) {
      this.range.date_end = date;
      this.range.mask_end = moment(date).format('YYYY-MM-DD');
    },
  },

};
</script>
